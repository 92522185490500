// Copyright 2021
// Polus
import { StaticImage } from 'gatsby-plugin-image';
import * as React from 'react';

import Callout from '../../components/Callout';
import FeaturePageContainer from '../../components/features/FeaturePageContainer';
import ImageWithTextSection from '../../components/features/ImageWithTextSection';
import Layout from '../../components/Layout';
import Seo from '../../components/SEO';

const SpecialFormatting = (): React.ReactElement => (
  <Layout>
    <Seo title="Special Formatting" />
    <FeaturePageContainer>
      {/* TODO: make this a shared component */}
      <div className="flex flex-col items-center justify-around space-y-8 space-x-0 md:flex-row md:space-y-0 md:space-x-8">
        <div className="w-full max-w-[28rem]">
          <iframe
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            frameBorder="0"
            height="320"
            src="https://www.youtube.com/embed/2-RrqdxH5oo"
            title="YouTube video player"
            width="100%"
            allowFullScreen
          />
        </div>
        <div className="md:w-1/2">
          <h1 className="mb-3 text-2xl font-bold md:text-4xl">Special Formatting</h1>
          <p>
            You remember flashcards better if you've created them yourself. With special formatting,
            Studius helps you style your cards' content with presets, HTML and Markdown to create a
            truly unique learning experience with rich flashcards.
          </p>
        </div>
      </div>

      <ImageWithTextSection
        content="Select text when you're editing a card and style it using the rich text editor. Pick from bold, italic, strikethrough and highlight."
        header="Create rich cards"
        image={
          <StaticImage
            alt="Rich Text Editor example for editing a flashcard"
            quality={100}
            src="../../images/special-formatting/rich-text-editor.png"
          />
        }
      />

      <ImageWithTextSection
        content="Copy any existing HTML-formatted text and paste it into a card. Studius will recognise pre-existing formatting and include it in your card."
        header="Paste HTML"
        image={
          <StaticImage
            alt="HTML example for editing a flashcard"
            className="w-5/6"
            quality={100}
            src="../../images/special-formatting/paste-html.png"
          />
        }
      />

      <ImageWithTextSection
        content="Use Markdown, a powerful and readable way for adding rich formatting to plain text, to style your card content. If you haven't used Markdown in the past, we highly recommend you check it out!"
        header="Write Markdown"
        image={
          <StaticImage
            alt="Markdown example for editing a flashcard"
            quality={100}
            src="../../images/special-formatting/markdown.png"
          />
        }
      />

      <Callout
        label="Convinced? Use Studius to study rich cards with HTML and Markdown."
        isPremium
      />
    </FeaturePageContainer>
  </Layout>
);

export default SpecialFormatting;
